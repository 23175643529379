import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import type {ReactElement} from 'react';
import {createSearchParams, Link, useLocation, useSearchParams} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import EnergyStarLogo from '@/assets/footer/energy_star_logo.png';
import NfrcLogo from '@/assets/footer/nfrc_logo.jpg';
import ScsRecycledLogo from '@/assets/footer/scs_recycled_logo.jpg';
import UsaBuiltLogo from '@/assets/footer/usa_built_logo.jpg';
import WarrantyLogo from '@/assets/footer/warranty_logo.png';
import JdPowerLogo from '@/assets/footer/jd_power.png';
import {useOfferQuery} from '@/queries/offer';
import type {LeadToken} from '@/types/lead-token';

const Disclaimer = () : JSX.Element => {
    return <Grid sx={{justifyContent: 'center'}}>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>1</sup>See Renewal by Andersen® Products and Installation Transferable Limited Warranty for details.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>2</sup>Values are based on comparison of Renewal by Andersen® double-hung window U-Factor to the
            U-Factor for clear dual pane glass non-metal frame default values from the 2006, 2009, 2012, 2015, and
            2018 International Energy Conservation Code "Glazed Fenestration" Default Tables.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>*</sup>Based on Medallia homeowner satisfaction surveys of Renewal by Andersen customers January
            2019-December 2023. Average scores between 9 and 10 using Net Promoter Score methodology on all survey
            questions after installation project completed.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>*</sup><sup>*</sup>When Renewal by Andersen® products were tested against five leading competitors’
            painted vinyl
            window products.
        </Typography>
        <Typography variant={'body2'}>
            <sup>†</sup>It is the only warranty among top selling window companies that meets all of the following
            requirements: easy to understand terms, unrestricted transferability, installation coverage, labor
            coverage, geographically unrestricted, coverage for exterior color, insect screens and hardware, and
            no maintenance requirement. Visit <a href={'https://www.renewalbyandersen.com/nationsbest'} target={'_blank'} rel="noreferrer">
            renewalbyandersen.com/nationsbest</a> for details.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            <sup>‡</sup>Review aggregator survey of 5-star reviews among leading national full-service window
            replacement companies. January 2024 Reputation.com.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            "ENERGY STAR" is a registered trademark of the U.S. Environmental Protection Agency.
        </Typography>
        <Typography variant={'body2'} sx={{mt: 1}}>
            ©2025 Andersen Corporation. All rights reserved. ©2025 Lead Surge LLC.
        </Typography>
    </Grid>;
};

const CertificationLogos = () : JSX.Element => {
    const logoHeight = '100px';
    return <Grid container sx={{justifyContent: 'center'}}>
        <Grid container size={{xs: 12}} sx={{display: 'flex', justifyContent: 'center', padding: 1, mb: 2}}>
            <Box
                component={'img'}
                alt="Energy Star"
                src={EnergyStarLogo}
                sx={{maxHeight: logoHeight, margin: '0 20px 20px 0'}}
            />
            <Box
                component={'img'}
                alt="Warranty"
                src={WarrantyLogo}
                sx={{maxHeight: logoHeight, marginRight: '20px'}}
            />
            <Box
                component={'img'}
                alt="National Fenestration Rating Council"
                src={NfrcLogo}
                sx={{maxHeight: logoHeight, pr: 2}}
            />
            <Box
                component={'img'}
                alt="Certified Recycled Content SCS Global Services"
                src={ScsRecycledLogo}
                sx={{maxHeight: logoHeight, mr: '20px'}}
            />
            <Box
                component={'img'}
                alt="Custom built in the USA using US and imported parts"
                src={UsaBuiltLogo}
                sx={{maxHeight: logoHeight}}
            />
            <Box
                component={'img'}
                alt="Highest in Customer Satisfaction with Window and Patio Door Manufacturer Brands, 5 years in a row"
                src={JdPowerLogo}
                sx={{maxHeight: logoHeight}}
            />
        </Grid>
    </Grid>;
};

const Footer = ({leadToken} : {leadToken : LeadToken}) : ReactElement => {
    const offerQuery = useOfferQuery(leadToken.pageId);
    const {pathname} = useLocation();
    const [searchParams] = useSearchParams();

    const scrollWithOffset = (el : HTMLElement) => {
        const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
        const yOffset = -150;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    };

    return <Grid container>
        <Grid size={{xs: 12}}>
            <CertificationLogos/>
        </Grid>
        <Grid size={{xs: 12}}>
            {offerQuery.data && pathname === '/' && leadToken.zip
                ? <Grid container size={{xs: 12}} justifyContent={'center'}>
                    <Grid justifyContent={'center'} size={{xs: 10}} sx={{padding: 3}}>
                        <Typography
                            variant={'body2'}
                            className='dangerous'
                            dangerouslySetInnerHTML={{__html: offerQuery.data.offerDisclaimer ?? ''}}
                        />
                    </Grid>
                </Grid>
                : <></>
            }
        </Grid>
        <Grid
            container
            spacing={2}
            sx={{
                backgroundColor: '#001722',
                color: '#fff',
                alignItems: 'center',
                flexWrap: 'wrap-reverse',
                minHeight: 150,
                py: 5,
                mx: 0,
            }}>
            <Grid size={{xs: 12, md: 10}} offset={{md: 1}}>
                <Disclaimer/>
                <>
                    <Link to={'https://www.renewalbyandersen.com/about/terms-of-use'} style={{color: '#fff'}}>
                        Terms of Service</Link> | <HashLink
                        to={{pathname: '/privacy',
                            search: `?${createSearchParams(
                                Object.fromEntries(searchParams)
                            ).toString()}`}}
                        style={{color: '#fff'}}
                    >
                    Privacy Policy</HashLink> | <HashLink
                        to={{
                            pathname: '/privacy',
                            search: `?${createSearchParams(
                                Object.fromEntries(searchParams)
                            ).toString()}`,
                            hash: '#privacy-policy',
                        }}
                        style={{color: '#fff'}}
                        scroll={el => {
                            scrollWithOffset(el);
                        }}>
                    Privacy Notice for CA Residents</HashLink>
                </>
            </Grid>
        </Grid>
    </Grid>;
};

export default Footer;
