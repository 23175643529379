import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';

const totalQuizSteps = 3;

const styles = {
    container: {
        width: '50%',
        height: '24px',
        overflow: 'hidden',
        padding: 5,
        border: '1px solid #000',
    },
    progressBar: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontWeight: 'bold',
        transition: 'width 0.3s ease',
    } satisfies React.CSSProperties,
};

const QuizProgressBar = ({stepNumber} : {stepNumber : number}) : React.ReactElement => {
    const adjustedStepNumber = stepNumber > totalQuizSteps ? totalQuizSteps : stepNumber;
    const progress = (adjustedStepNumber / totalQuizSteps) * 100;
    return <Grid container size={{xs: 12}}>
        <Grid size={{xs: 12}}>
            <Typography variant={'h5'} sx={{mb: 2, fontSize: '11pt'}}>
                Question {adjustedStepNumber} of {totalQuizSteps}
            </Typography>
        </Grid>
        <Grid size={{xs: 12}} sx={{display: 'flex', justifyContent: 'center'}}>
            <div style={styles.container}>
                <div
                    style={{
                        ...styles.progressBar,
                        width: `${progress}%`,
                        backgroundColor: '#77ad5c',
                    }}
                />
            </div>
        </Grid>
    </Grid>;
};

export default QuizProgressBar;
