import {PhoneEnabled} from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Box, List, ListItem, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import type {ReactElement} from 'react';
import {useEffect} from 'react';
import ConfirmationImage from '/src/assets/confirmation-image.jpg';
import {useLeadTokenContext} from '@/contexts/LeadTokenContext';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import {useLsrcPhoneNumberQuery} from '@/queries/lsrc';
import type {LeadToken} from '@/types/lead-token';
import {defaultPhoneNumber} from "@/components/PhoneButton.tsx";

const ConfirmationDisplay = ({leadToken} : {leadToken : LeadToken}) : ReactElement => {
    const phoneNumberQuery = useLsrcPhoneNumberQuery(leadToken.sourceId);
    const eventMutation = useCreateLeadTokenEventMutation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = async () => {
        await eventMutation.mutateAsync({leadTokenId: leadToken.id, type: 'click', name: 'confirmation_phone_button'});
    };

    return (
        <Grid container justifyContent={'center'}>
            <Grid
                size={{xs: 12, lg: 6}}
                order={{xs: 2, lg: 1}}
            >
                <Box
                    component={'img'}
                    alt="Renewal by Anderson"
                    src={ConfirmationImage}
                    width={'100%'}
                />
            </Grid>
            <Grid
                size={{xs: 12, lg: 6}}
                sx={{textAlign: 'center'}}
                order={{xs: 1, lg: 2}}
            >
                <Stack>
                    <Box sx={{
                        mt: 10,
                        mx: 10,
                        border: '2px solid #6CC14C',
                    }}
                    >
                        <CheckCircleIcon sx={{fontSize: '72px', color: '#6CC14C', my: 3}}/>
                        <Typography variant={'h5'} sx={{textTransform: 'uppercase', fontWeight: '500'}}>
                            Thank you. We are currently processing your information.
                        </Typography>
                        <Typography sx={{fontSize: '18px', mt: 2, mb: 3, px: 7}}>
                            We’ve replaced <b>more than 10 million windows in over 1 million homes</b>, and we look
                            forward to handling your home improvement project.
                        </Typography>
                    </Box>

                    <Grid>
                        <Typography variant={'h5'} sx={{mt: 5}}>
                        Next steps?
                        </Typography>
                        <List sx={{listStyle: 'decimal', mx: 10}}>
                            <ListItem sx={{display: 'list-item'}}>
                                You will be hearing from a Renewal by Andersen team member shortly to discuss your
                                project.
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                We will send a local rep to your home to personally take measurements and discuss style
                                options and colors for your windows and doors.
                            </ListItem>
                            <ListItem sx={{display: 'list-item'}}>
                                You receive a customized, down-to-the-penny price quote on the spot – with no estimates
                                or “guesstimates”.
                            </ListItem>
                        </List>
                    </Grid>
                    <Typography sx={{fontSize: '18px', fontWeight: '700', mx: isMobile ? 5 : 25, mt: 5}}>
                        But why wait? Call us directly and get started today!
                    </Typography>
                    <Grid container sx={{display: 'flex', justifyContent: 'center', mt: 2, mb: 10}}><Box
                        color="#000"
                        bgcolor={'#a1dB80'}
                        borderRadius={6}
                        px={4}
                        py={1.5}
                        display="flex"
                        width={'250px'}
                    >
                        <PhoneEnabled sx={{transform: 'scaleX(-1)'}}/>&nbsp;
                        <Typography variant={'body1'} display="inline">
                            <a
                                style={{textDecoration: 'none', color: 'inherit', fontWeight: '700', marginLeft: 5}}
                                href={'tel:' + (phoneNumberQuery.data ?? defaultPhoneNumber)}
                                onClick={handleClick}
                            >
                                Call {phoneNumberQuery.data ?? defaultPhoneNumber}
                            </a>
                        </Typography>
                    </Box></Grid>
                </Stack>
            </Grid>
        </Grid>
    );
};

const Confirmation = () : JSX.Element => {
    const {leadToken} = useLeadTokenContext();

    if (!leadToken) {
        return <></>;
    }

    return <ConfirmationDisplay leadToken={leadToken}/>;
};

export default Confirmation;
