import {Box, List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import {useEffect} from 'react';

const Privacy = () : JSX.Element => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <Grid container justifyContent={'center'}>
            <Grid size={{xs: 12, md: 10}}>
                <Box><>
                    <Typography variant="h5" sx={{textDecoration: 'underline', textAlign: 'center'}}>
                        Consumer Privacy, Texting and Dispute Resolution Policy
                    </Typography>
                    <Typography variant='body1' sx={{my: 2}}>Last updated: November 2023</Typography>
                    <Typography variant="body1" sx={{fontWeight: 600}}>
                        YOUR USE OF THIS WEBPAGE MEANS THAT YOU ARE AGREEING TO ALL FOUR PARTS OF THIS CONSUMER
                        PRIVACY, TEXTING AND DISPUTE RESOLUTION POLICY (THE “PRIVACY POLICY” OR THIS “AGREEMENT”)
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        At Lead Surge LLC (hereinafter, “Lead Surge”, “We,” “Us,” or “Our”), we recognize that
                        earning and retaining your trust is one of the most important things we do as a business.
                        Protecting your privacy is a responsibility we take very seriously.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        There are four parts to this Consumer Privacy, Texting and Dispute Resolution Policy. The first
                        part is an overall discussion to help you understand that Lead Surge only collects, uses, sells
                        and/or shares the personal data you provide to us on our websites, mobile applications, via
                        email, texting or telephone calls with Renewal by Andersen, hereby abbreviated as “RbA”. In
                        addition, the first part also includes information on how we safeguard and collect sensitive
                        personal information (if at all), the categories of sensitive personal information to be
                        collected and the purposes for which the categories of sensitive personal information are
                        collected or used and whether such information is sold or shared. Finally, this Consumer Privacy
                        Policy also contains information on the length of time our company intends to retain each
                        category of personal information, including sensitive personal information.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        The second part is a specific discussion of some of these same issues, but also explains your
                        rights as a California resident under the California Privacy Protection Act (“CPPA”), which was
                        amended by the California Privacy Rights Act (“CPRA”) and discusses certain additional
                        disclosures and rights.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        The third part addresses texting and our texting policies and practices.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        The fourth part is our dispute resolution section, which discusses how disputes that may arise
                        between us are to be handled.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        This Privacy Policy does not address, and we are not responsible for, the privacy, information
                        or other practices of any third party, including any third-party finance companies, suppliers,
                        manufacturers, website operators, or any third party operating any site to which our websites
                        contain a link. By downloading, accessing or using our websites or content on our websites, or
                        using any electronic application on a mobile phone, tablet or similar device ("Device") to
                        purchase any Renewal by Andersen product or seek third-party financing for any purchase from
                        Lead Surge, or providing information to us in connection with any such transaction or any of our
                        websites, mobile applications or other sites or services, you acknowledge that you have read and
                        understood the terms and conditions of this Privacy Policy and the <a
                            href={'https://www.andersenwindows.com/support/privacy/'} target={'_blank'} rel="noreferrer">
                        Renewal by Andersen Privacy Policy.</a>
                    </Typography>
                    <Typography variant="h6">Who We Are</Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We have been providing quality consumer information interest in Renewal by Andersen and its
                        authorized dealers (together, “RbA”) products and quality services throughout the United States
                        since November 11, 2011. Quality matters to us, it is our commitment to provide a better
                        community by helping one family at a time to have a comfortable and efficient home.
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item'}}>
                            Renewalbyandersenwindowsanddoors.com (together with any other Lead Surge websites) is our
                            flagship website, where we make available RbA’s complete range of products and services
                            directly to consumers.
                        </ListItem>
                        <ListItem sx={{display: 'list-item'}}>
                            At Lead Surge, we also work with affiliates, distributors and suppliers. These are
                            companies that may manufacture, distribute, finance and install our products and services to
                            consumers, and may also host our internet websites and provide us with information on
                            consumers interested in our products and services. Some of those affiliates have their own
                            individual websites.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        If you provide personal data directly to our affiliates, distributors or suppliers, the terms of
                        their respective privacy policies will apply to such disclosures. You should carefully review
                        the privacy policies of the affiliate partner, distributor or supplier prior to providing
                        personal data to such third parties.
                    </Typography>
                    <Typography variant="h6">Privacy Policy – Part 1</Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        Collection of your personal data
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        In the course of our business, we collect and process personal data in different ways. We may
                        collect personal data you give us directly, but we also collect data from how you interact with
                        our services, for example by using cookies on this website. We may also receive personal data
                        from third parties.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        The personal data we collect about you may include, but is not limited to your name, email
                        address, postal address, phone number, billing information, survey responses and other
                        information you may provide about yourself to our site, your IP address and web browser
                        software.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        You can choose not to provide personal data to us, but in
                        general, some personal data about you is required in order for you to receive relevant offerings
                        from Lead Surge and its partners.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}></Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item'}}>
                            <b>Information that you provide to us:</b> We receive and store any personal data you enter
                            on our websites or give us in any other way. For example, in order to purchase products or
                            services, it may be necessary for you to disclose personal data, including your name,
                            address, email address, phone number, credit card details and other personally identifying
                            information. If you complete any sort of credit application with a third party to finance a
                            purchase of RbA products and services, we may see that information as well.
                        </ListItem>
                        <ListItem sx={{display: 'list-item'}}>
                            <b>Information about others:</b> You may have the opportunity to provide contact information
                            for other people through our websites. You will need to submit that individual's personal
                            data. You must obtain the consent of other individuals prior to providing us with their
                            personal data.
                        </ListItem>
                        <ListItem sx={{display: 'list-item'}}>
                            <b>Information from Other Sources:</b> We also may periodically obtain personal data about
                            you that is publicly available, from affiliated entities, business partners, and other
                            independent third-party sources and add it to other information about you. For example, if
                            you visit Lead Surge by "clicking through" from a site operated by one of our business
                            partners, and you have registered with that partner, then personal data about you that you
                            have provided to that partner may be shared with us, such as contact information and
                            demographic information. As another example, if you access third-party services, such as
                            social media services, through our website or before coming to our website, we may collect
                            information such as your username and other information made available to us through those
                            services. We will work with our existing partners and potential future partners to improve
                            and personalize your use of our website in accordance with the practices outlined in this
                            Privacy Policy.
                        </ListItem>
                        <ListItem sx={{display: 'list-item'}}>
                            <b>Automatic Information:</b> We automatically collect certain information from your
                            computer or mobile device when you visit our website or any of our affiliates' web pages
                            that are hosted by us. For example, we collect your IP address, web browser software, and
                            referring website. To help us analyze the usage of our websites and related services, and to
                            send more relevant communications to you, we also collect and keep track of your online
                            activity on our website, such as content viewed, and pages visited.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        How we use your personal data
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We use the personal data that we collect about you only for specific purposes. This includes,
                        but is not limited to offering RbA products and services to you via authorized RbA dealers,
                        managing your access to and use of our website and publishing any reviews, ratings and photos,
                        communicate with you about your account and experiences with us or use of our site and services,
                        to respond to, or follow up on, your comments and questions, and otherwise provide customer
                        service, notify you about changes to our terms or this Privacy Policy, ask you to leave a review
                        or take a survey, operate and improve our site and services, personalize your experience on our
                        site, measure interest in and improve our site and services, notify you about RbA promotions and
                        special offers, to let you partake in a prize drawing, competition or complete a survey,
                        protect, investigate and deter against fraudulent, unauthorized or illegal activity, administer
                        and protect our business and to resolve disputes or troubleshoot problems.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We will only use your personal data when the law allows us to. Most commonly, we will use your
                        personal data in the following circumstances:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Where we need to perform an RbA contract to be entered into with you.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Where it is necessary for our legitimate interests (or those of a third party) and your
                            interests and fundamental rights do not override those interests.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            With your consent.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Where we need to comply with a legal or regulatory obligation.
                        </ListItem>
                    </List>

                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        Marketing
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We may call, text, email or send you marketing messages with information about RbA deals and
                        product news, offers and other special promotions that we believe that you will find valuable,
                        interesting or informative.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        You will receive marketing messages from us if you have given your consent, if you have
                        requested them, or if you purchased goods or services from us and you have not opted out of
                        receiving marketing messages.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        If you are no longer interested in receiving future marketing communications from Lead Surge,
                        you have several choices for opting out, you may unsubscribe at any time by following the
                        directions and link included in each email marketing message we send or by writing to us with
                        your request (our contact information is set forth further below in this Privacy Policy).
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        Please note that even if you opt-out of receiving future marketing communications from Lead
                        Surge, we will continue to contact you regarding your purchases and regarding administrative and
                        service-related information such as warranty servicing and installation scheduling. We reserve
                        the right to send you other non-marketing communications, including service announcements,
                        administrative messages, and surveys without offering you the opportunity to opt-out of
                        receiving them.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        With whom we share your personal data
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We may store the personal data and submissions you make to Lead Surge (your "Content") and share
                        it with authorized RbA dealers, our affiliates, distributors or suppliers, and use it for
                        promotional purposes.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        Lead Surge may share your personal data with other parties, including service providers who work
                        on our behalf. If we sell or divest our business or any part of it, and your personal data
                        relates to such sold or divested part of our business, or if we merge with another business, we
                        will share your personal data with the new owner of the business or our merger partner,
                        respectively. If we are legally obliged to do so, we will share personal data to protect our
                        customers, the site, as well as our company and our rights and property. In any instance in
                        which we share or sell your personal data to a third-party or disclose it to a service provider
                        or contractor for a business purpose, we will enter into an agreement with such third-party,
                        service provider or contractor that: (1) specifies that the personal information is sold or
                        disclosed by us only for limited and specified purposes; (2) obligates the third-party, service
                        provider, or contractor to comply with applicable obligations under the relevant current
                        legislation and obligate those persons to provide the same level or privacy protection as is
                        required by relevant current legislation; (3) grants us the right to take reasonable and
                        appropriate steps to help ensure that the third-party, service provider or contractor uses the
                        personal information transferred in a manner consistent with its obligations under relevant
                        current legislation; (4) requires the third-party, service provider, or contractor to notify us
                        if it makes a determination that it can no longer meet its obligations under this title; and (5)
                        grants us the right, upon notice (including under the previous sentence (4)), to take reasonable
                        and appropriate steps to stop and remediate unauthorized use of personal information.
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            <b>Social Media Services.</b> If you connect to Lead Surge using Facebook, we may show you
                            reviews, photos and comments that your Facebook friends have posted, shared, saved or
                            purchased on Lead Surge. Note that, if you have Facebook friends who are using Lead Surge,
                            they may also share personal data about you with us through Facebook. If you wish to prevent
                            that sharing, please review your Facebook privacy settings. You may also be able to choose
                            to access third-party social media websites and services through Lead Surge (such as
                            Facebook and Twitter). When you do so, you are sharing personal data with those sites, and
                            the personal data you share is governed by their privacy policies. You may also be able to
                            modify your privacy settings with these third-party social media websites.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            <b>Service Providers.</b> Some of the personal data we collect is shared with service
                            providers (our affiliates, distributors or suppliers) who are directly involved in
                            fulfilling the purchases you have made from us. We only provide our service providers with
                            the necessary information (e.g., your name, address for installation work, your local phone
                            number, your mobile phone number, etc.).
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            <b>Third-Party Vendors.</b> We may also share your personal data with third-party vendors
                            who provide services or functions on our behalf, including business analytics, payment
                            processing, customer service, marketing, public relations, distribution of surveys or
                            sweepstakes programs, and fraud prevention. We may also authorize third-party vendors to
                            collect personal data on our behalf, including, for example, as necessary to operate
                            features of our websites or to facilitate the delivery of online advertising tailored to
                            your interests. Third-party vendors have access to and may collect personal data only as
                            needed to perform their functions and are not permitted to share or use the personal data
                            for any other purpose. We currently contract with several third-party online partners to
                            help manage and optimize our internet business and communications. We use the services of
                            third-party marketing companies to help us measure the effectiveness of our advertising and
                            how visitors use our website.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            <b>Referring Websites.</b> If you were referred to Lead Surge from another website, we may
                            share your registration information, such as your name, email address, mailing address,
                            telephone number and home remodeling and improvement preferences, about you with that
                            referring website. We have not placed limitations on the referring websites' use of personal
                            data, and we encourage you to review the privacy policies of any website that referred you
                            to Lead Surge.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            <b>Companies within our Corporate Family.</b> We may share your personal data within the
                            Lead Surge group of companies. This sharing enables us to provide you with information about
                            Lead Surge or RbA products and services which might interest you. To the extent that our
                            corporate affiliates have access to your personal data, they will follow practices that are
                            at least as restrictive as the practices described in this Privacy Policy. They also will
                            comply with applicable laws governing the transmission of promotional communications and, at
                            a minimum, give you an opportunity in any commercial email they send to choose not to
                            receive such email messages in the future.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        We may also share or disclose personal data if we believe, at our sole discretion, this to be
                        necessary:
                    </Typography>

                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            to comply with legitimate and enforceable subpoenas, court orders, or other legal process;
                            to establish or exercise our legal rights; to defend against legal claims; or as otherwise
                            required by law. In such cases we reserve the right to raise or waive any legal objection or
                            right available to us;
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            to investigate, prevent, or take action regarding illegal or suspected illegal activities;
                            to protect and defend the rights, property, or safety of Lead Surge, our users, or others;
                            and in connection with our Terms of Use or other agreements, including with our partners or
                            suppliers;
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            following a corporate transaction, such as divestiture, merger, acquisition, consolidation
                            or asset sale, or in the unlikely event of bankruptcy;
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            with law enforcement agencies and other governmental bodies (if we are legally obliged to do
                            so).
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        We also may share aggregate or anonymous information with third parties, including advertisers
                        and investors. For example, we may tell our advertisers the number of visitors our website
                        receives or the most popular products we sell. This information does not contain any personal
                        data and is used to develop content and services we hope you will find of interest.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        How long will we retain your personal data?
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We will only retain your personal data for as long as necessary to fulfill the purposes we
                        collected it for, including for the purposes of satisfying any legal, accounting, or reporting
                        requirements.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        To determine the appropriate retention period for personal data, we consider the amount, nature,
                        and sensitivity of the personal data, the potential risk of harm from unauthorized use or
                        disclosure of your personal data, the purposes for which we process your personal data and
                        whether we can achieve those purposes through other means, and the applicable legal
                        requirements.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        Cookies and other web technologies
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We collect personal data via cookies and similar technologies. "Cookies" are pieces of
                        information that are stored by your web browser on your computer's hard disk, for record-keeping
                        purposes. Cookies contain basic information about your Internet use, but they do not normally
                        identify you personally. Your browser sends these cookies back to us every time you revisit our
                        website, so it can recognize your computer or mobile device and personalize and improve your
                        site experience. For instance, when we use a cookie to identify you, you would not have to log
                        in with a password more than once, thereby saving time while on our site. Cookies can also
                        enable us to track and target the interests of our users to enhance the experience on our site.
                        The usage of a cookie is in no way linked to any personally identifiable information on our
                        site.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        By continuing to use our websites, you consent to our use of cookies. You can find more
                        information about cookies in general, including how to see what cookies are installed on your
                        (mobile) device and how to manage and delete them on www.allaboutcookies.org and
                        http://www.youronlinechoices.com/.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        Please note that if you choose to delete Lead Surge cookies, your access to some functionality
                        and areas of our website may be degraded or restricted.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        How we protect your personal data
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        At Lead Surge, we seek to maintain the integrity and security of your personal data. We have
                        implemented and maintain appropriate physical, administrative, technical, and organizational
                        measures to protect the personal data you provide us against unauthorized or unlawful access,
                        use or disclosure, and against accidental loss, damage, alteration or destruction.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We seek to store your personal data in secure operating environments that are not accessible to
                        the general public, and to have security measures in place at our physical facilities to protect
                        against the loss, misuse or alteration of your personal data by our employees or third parties.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        However, no data transmission over the internet or data storage environment can be guaranteed to
                        be 100% secure, so we cannot give an absolute assurance that the personal data you provide to us
                        will be secure at all times. Lead Surge will rely on you telling us if you experience any
                        unusual events that may indicate a breach in your information security. We will then seek to
                        investigate whether this was related to the data transmissions from or to Lead Surge and let you
                        know what steps can be taken and have been taken to help rectify the problem.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        External Links
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        Our site may include links to third-party sites, plug-ins and applications. If you access other
                        sites from the links provided on our site, the operators of these sites may be able to collect
                        or share information about you. This information will be used in accordance with their privacy
                        policy, which may be different from our Privacy Policy. We do not control these third-party
                        websites and recommend that you review the privacy policies posted on these third-party websites
                        in order to fully understand their procedures for the collection, use and disclosure of personal
                        data.
                    </Typography>
                    <Typography variant="h6" id={'privacy-policy'}>Privacy Policy – Part 2</Typography>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        California Consumer Privacy Act/California Privacy Rights Act- Privacy Statement (“CCPA/CPRA
                        Statement”)
                    </Typography>
                    <Typography variant="body1">
                        This second part of our Privacy Policy supplements the information contained above and applies
                        solely to visitors, users, and others who reside in the State of California. We adopt this
                        notice to comply with privacy laws. Any terms defined in the CCPA/CPRA have the same meaning
                        when used in this notice.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Your rights under CCPA/CPRA
                    </Typography>
                    <Typography variant="body1">
                        As of January 1, 2020, California law permits residents of California to request certain details
                        about how their personal information is shared with third parties or affiliated companies for
                        direct marketing purposes. As of January 1, 2023, California laws will be expanded to allow
                        consumers the right to request that a business delete, correct, or disclose any personal
                        information about consumers that the business has collected directly from consumers.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        In the first part of our Privacy Policy, we explained how Lead Surge collects, uses and seeks to
                        safeguard the personal data you provide to us on our websites, mobile applications, via email,
                        texting or telephone calls, and through our service providers and distribution partners.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Understanding Personal Information under CCPA/CPRA
                    </Typography>
                    <Typography variant="body1">
                        Pursuant to California’s privacy laws, we want to clarify to you that we collect information
                        that identifies, relates to, describes, references, is capable of being associated with, or
                        could reasonably be linked, directly or indirectly, with a particular consumer or device. This
                        information is called “personal information” under CCPA/CPRA.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>Personal information does not include:</Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Publicly available information from government records.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            De-identified or aggregated consumer information.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Information excluded from the CCPA's scope, like:
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 6}} disablePadding={true}>
                            health or medical information covered by the Health Insurance Portability and Accountability
                            Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or
                            clinical trial data.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 6}} disablePadding={true}>
                            personal information covered by certain sector-specific privacy laws, including the Fair
                            Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial
                            Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        How We Collect Personal Information and Sensitive Personal Information
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We obtain certain categories of personal information (listed as A through O, below) from certain
                        sources:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Directly from our customers. For example, in order to purchase products or services, it is
                            necessary for you to disclose personal data, including your name, address, email address,
                            phone number, credit card details and other personally identifying information. If you
                            complete any sort of credit application with a third party to finance a purchase of RbA
                            products and services, we may see that information as well.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Directly and indirectly from activity on our website
                            (renewalbyandersenwindowsanddoors.com). For example, from submissions through our website
                            portal or website usage details collected automatically.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            From third parties that interact with us in connection with the services we perform. For
                            example, if you visit Lead Surge by "clicking through" from a site operated by one of our
                            business partners, and you have registered with that partner, then personal data about you
                            that you have provided to that partner may be shared with us, such as contact information
                            and demographic information. As another example, if you access third-party services, such as
                            social media services, through our website or before coming to our website, we may collect
                            information such as your username and other information made available to us through those
                            services. We will work with our existing partners and potential future partners to improve
                            and personalize your use of our website in accordance with the practices outlined in this
                            Privacy Policy.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        In particular, here are examples of categories of personal information from consumers, and we
                        have noted which categories we have collected from consumers within the last twelve (12) months:
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Category</TableCell>
                                <TableCell>Examples</TableCell>
                                <TableCell>Collected</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>A. Identifiers.</TableCell>
                                <TableCell>A real name, alias, postal address, unique personal identifier, online
                                    identifier, Internet Protocol address, email address, account name, Social Security
                                    number, driver's license number, passport number, or other similar
                                    identifiers.</TableCell>
                                <TableCell>YES</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>B. Personal information categories listed in the California Customer Records
                                    statute (Cal. Civ. Code § 1798.80(e)).</TableCell>
                                <TableCell>A name, signature, Social Security number, physical characteristics or
                                    description, address, telephone number, passport number, driver's license or state
                                    identification card number, insurance policy number, education, employment,
                                    employment history, bank account number, credit card number, debit card number, or
                                    any other financial information, medical information, or health insurance
                                    information. Some personal information included in this category may overlap with
                                    other categories and may also be sensitive personal information</TableCell>
                                <TableCell>YES</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>C. Protected classification characteristics under California or federal
                                    law.</TableCell>
                                <TableCell>Age (40 years or older), race, color, ancestry, national origin, citizenship,
                                    religion or creed, marital status, medical condition, physical or mental disability,
                                    sex (including gender, gender identity, gender expression, pregnancy or childbirth
                                    and related medical conditions), sexual orientation, veteran or military status,
                                    genetic information (including familial genetic information).</TableCell>
                                <TableCell>YES</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>D. Commercial information.</TableCell>
                                <TableCell>Records of personal property, products or services purchased, obtained, or
                                    considered, or other purchasing or consuming histories or tendencies.</TableCell>
                                <TableCell>YES</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>E. Biometric information.</TableCell>
                                <TableCell>Genetic, physiological, behavioral, and biological characteristics, or
                                    activity patterns used to extract a template or other identifier or identifying
                                    information, such as fingerprints, faceprints, voiceprints, iris or retina scans,
                                    keystroke, gait, or other physical patterns, and sleep, health, or exercise
                                    data.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>F. Internet or other similar network activity.</TableCell>
                                <TableCell>Browsing history, search history, information on a consumer’s interaction
                                    with a website, application, or advertisement.</TableCell>
                                <TableCell>YES</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>F. Internet or other similar network activity.</TableCell>
                                <TableCell>Browsing history, search history, information on a consumer’s interaction
                                    with a website, application, or advertisement.</TableCell>
                                <TableCell>YES</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>G. Geolocation & Precise geolocation data.</TableCell>
                                <TableCell>Physical location or movements.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>H. Sensory data.</TableCell>
                                <TableCell>Audio, electronic, visual, thermal, olfactory, or similar
                                    information.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>I. Professional or employment-related information.</TableCell>
                                <TableCell>Current or past job history or performance evaluations.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>J. Non-public education information (per the Family Educational Rights and
                                    Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</TableCell>
                                <TableCell>Education records directly related to a student maintained by an educational
                                    institution or party acting on its behalf, such as grades, transcripts, class lists,
                                    student schedules, student identification codes, student financial information, or
                                    student disciplinary records.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>K. Inferences drawn from other personal information.</TableCell>
                                <TableCell>Profile reflecting a person’s preferences, characteristics, psychological
                                    trends, predispositions, behavior, attitudes, intelligence, abilities, and
                                    aptitudes.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>L. Access to accounts.</TableCell>
                                <TableCell>Personal information that reveals a consumer’s account log-in, financial
                                    account, debit card, or credit card number in combination with any required security
                                    or access code, password, or credentials allowing access to an account.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>M. Personal characteristics.</TableCell>
                                <TableCell>Personal information that reveals a consumer’s racial or ethnic origin,
                                    religious or philosophical beliefs, or union membership.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>N. Messages.</TableCell>
                                <TableCell>Personal information that reveals the contents of a consumer's mail, email,
                                    and text messages unless the business is the intended recipient of the
                                    communication.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>O. Other sensitive personal information</TableCell>
                                <TableCell>The processing of biometric information for the purpose of uniquely
                                    identifying a consumer, personal information collected and analyzed concerning a
                                    consumer's health, and Personal information collected and analyzed concerning a
                                    consumer's sex life or sexual orientation.</TableCell>
                                <TableCell>NO</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Use of Personal Information under CCPA/CPRA
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We may use or disclose the personal information we collect for one or more of the following
                        business purposes:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            To fulfill or meet the reason for which the information is provided. For example, most
                            commonly this would be to fulfill your interest in, or purchase of, RbA products and
                            services.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            To provide you with information on RbA products or services that you request from us.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            To provide you with email alerts, event registrations and other notices concerning our
                            products or services, or events or news, that may be of interest to you.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            To carry out our obligations and enforce our rights arising from any contracts entered into
                            between you and us, including for billing and collections.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            To improve our website and present its contents to you.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            As necessary or appropriate to protect the rights, property or safety of us, our clients or
                            others.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            To respond to law enforcement requests and as required by applicable law, court order, or
                            governmental regulations.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            As described to you when collecting your personal information or as otherwise set forth in
                            the CCPA/CPRA.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or
                            other sale or transfer of some or all of our assets, whether as a going concern or as part
                            of bankruptcy, liquidation, or similar proceeding, in which personal information held by us
                            is among the assets transferred.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        We will not collect additional categories of personal information or use the personal
                        information we collected for materially different, unrelated, or incompatible purposes without
                        providing you notice.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Sharing Personal Information under CCPA/CPRA
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We may disclose your personal information to a third party for a business purpose. When we
                        disclose personal information for a business purpose, we enter a contract that describes the
                        purpose and requires the recipient to both keep that personal information confidential and not
                        use it for any purpose except performing the contract.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        In the preceding twelve (12) months, we have shared the following categories of personal
                        information (using the listed A through O categories from above) for a business purpose (also
                        listed above):
                    </Typography>
                    <Table>
                        <TableRow>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>Category A:</TableCell>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>Identifiers</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>Category B:</TableCell>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>California Customer Records personal
                                information categories.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>Category C:</TableCell>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>Protected classification characteristics under
                                California or federal
                                law.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>Category D:</TableCell>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>Commercial information.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>Category E:</TableCell>
                            <TableCell sx={{borderBottom: 'none', py: 0}}>Internet or other similar network
                                activity.</TableCell>
                        </TableRow>
                    </Table>
                    <Typography variant="body1" sx={{my: 2}}>
                        We disclose your personal information for a business purpose to the following categories of
                        third parties:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            RbA authorized dealers and any of our affiliates.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Service providers, such as individuals and companies that may manufacture, distribute,
                            finance and install RbA products and services to consumers, and may also host our internet
                            websites and provide us information on consumers interested in RbA products and services.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Third parties to whom you authorize us to disclose your personal information in connection
                            with products or services we provide to you.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        In the preceding twelve (12) months, we have not sold any personal information. “Sold” means the
                        disclosure of personal information to a third party for monetary or other valuable
                        consideration. We do not consider personal information as “sold” if, as discussed above, it was
                        disclosed for a business purpose to those individuals and companies that were necessary to
                        fulfill your transaction with us or RbA, such as to sell or install RbA products and services to
                        you.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We do not have any actual knowledge that we sell or share personal information of consumers
                        under 16 years of age.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We do not use or disclose sensitive personal information other than that allowed under the
                        CCPA/CPRA.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Your Rights and Choices under CCPA/CPRA
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        The CCPA/CPRA provides consumers (California residents) with specific rights regarding their
                        personal information. This section describes your CCPA/CPRA rights and explains how to exercise
                        those rights.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Right to Understand and Receive Your Specific Information
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        You have the right to request that we disclose certain information to you about our collection
                        and use of your personal information over the past 12 months. Once we receive and confirm your
                        verifiable consumer request, we will disclose to you:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            The categories of personal information we collected about you.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            The categories of sources for the personal information we collected about you.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Our business or commercial purpose for collecting or selling that personal information.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            The categories of third parties with whom we share that personal information.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            The specific pieces of personal information we collected about you (also called a data
                            portability request).
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            If we sold or disclosed your personal information for a business purpose, two separate lists
                            disclosing:
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 6}} disablePadding={true}>
                            Sales of personal information, identifying the personal information categories that each
                            category of recipient purchased; and
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 6}} disablePadding={true}>
                            Disclosures of personal information for a business purpose, identifying the personal
                            information categories that each category of recipient obtained.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        As of January 1, 2023, you will have the right to request that if we sell or share your personal
                        information or disclose it for a business purpose, we disclose to you the following information
                        upon receipt of a verifiable request from you:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            The categories of personal information that we collected about you.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            The categories of personal information that we sold, shared or disclosed about you (and if
                            we didn’t sell or share or disclose your information) and the categories of third parties to
                            whom we sold or shared it, by category or categories of personal information for each
                            category of third parties to whom the personal information was sold or shared.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            The categories of personal information that we disclosed about you (and if we didn’t
                            disclose your personal information) for a business purpose and the categories of persons to
                            whom the information was disclosed for a business purpose.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Right to Have Us Correct Inaccurate Personal Information
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            As of January 1, 2023, you will have the right to correct inaccurate personal information.
                            If we receive a verifiable consumer request from you to correct inaccurate personal
                            information, we will use commercially reasonable efforts to correct the same, as directed by
                            you.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Right to Opt-Out of Sales or Sharing of Your Specific Information
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        You have the right to request to opt out of our sale or sharing of your personal information
                        with third parties for their direct marketing purposes. This means that, if you opt out, going
                        forward, we will not sell or share your information with such third parties to use for their
                        purposes unless you later consent to us do so.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        As of January 1, 2023, you will also have the right to opt out of our sharing of your personal
                        information with third parties as well. If you so opt out, we will not share your information
                        with such third parties to use for their purposes unless you later give us consent to do so.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        To exercise this right, see the section titled “Exercising Your Rights” below.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Right to Delete Your Specific Information
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        You have the right to request that we delete any of your personal information that we collected
                        from you and retained, subject to certain exceptions. Once we receive and confirm your
                        verifiable consumer request, we will delete (and direct our service providers to delete) your
                        personal information from our records, unless an exception applies.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We may deny your deletion request if retaining the information is necessary for us or our
                        service providers to:
                    </Typography>
                    <List sx={{listStyle: 'decimal', pl: 4}}>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.)."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement if you previously provided informed consent."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText primary="Comply with a legal obligation."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Make other internal and lawful uses of that information that are compatible with the context in which you provided it."/>
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        As of January 1, 2023, if we received a verifiable consumer request from you to delete your
                        personal information, we will delete your personal information from our records and notify any
                        service providers or contractors to delete your information from their records as well. We will
                        also notify all third parties to whom we have sold or shared your personal information to delete
                        your personal information unless this proves impossible or involves a disproportionate effort.
                        To this end, we may keep a confidential record of deletion requests solely for the purpose of
                        preventing personal information that you request to be deleted from being sold, for compliance
                        with laws, or other purposes solely to the extent permissible under the CCPA/CPRA. We may not be
                        required to comply with your request to delete your personal information if it is reasonably
                        necessary for us, our service providers, or contractors to maintain your personal information in
                        order to:
                    </Typography>
                    <List sx={{listStyle: 'decimal', pl: 4}}>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Complete the transaction for which your personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated by you within the context of our ongoing business relationship with you, or otherwise to perform a contract between you and RbA."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Help to ensure security and integrity to the extent the use of your personal information is reasonably necessary and proportionate for those purposes."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Debug and identify and repair errors that impair existing intended functionality."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Exercise free speech, ensure the right of another consumer to exercise that consumer’s right of free speech, or exercise another right provided by law."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText primary="Comply with the California Electronic Communications Privacy Act"/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="Engage in public or peer-reviewed scientific, historical, or statical research that conforms or adheres to all other applicable ethics and privacy laws, when our deletion of personal information is likely to render impossible or seriously impair the ability to complete such research, if you have provided informed consent."/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText
                                primary="To enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us and compatible with the context in which you provided your personal information. "/>
                        </ListItem>
                        <ListItem sx={{display: 'list-item', py: 0}}>
                            <ListItemText primary="Comply with a legal obligation."/>
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Right to Limit Use and Disclosure of Sensitive Personal Information
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        You have the right to limit our use of your “sensitive personal information” to that which is
                        necessary to perform the services or provide the goods reasonably expected by an average
                        consumer who requests such goods or services. In addition, if we have notified you that we
                        disclose sensitive personal information for purposes other than those that are necessary to
                        perform the services or provide the goods reasonably expected by an average consumer who
                        requests the same, you have a right to limit the use or disclosure of that sensitive personal
                        information. If you request such limitations on the use of your sensitive personal data, we will
                        not use or share your information with third parties to use for their purposes unless you later
                        give us consent to do so.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        Sensitive Personal Information means personal information that reveals a consumer’s social
                        security, driver’s license, state identification card, or passport number, a consumer’s account
                        log-in, financial account, debit card, or credit card number in combination with any required
                        security or access code, password, or credentials allowing access to an account, a consumer’s
                        precise geolocation, a consumers racial or ethnic origin, religious or philosophical beliefs, or
                        union membership, the contents of a consumer’s mail, email, and text messages unless the
                        business in the intended recipient of the communication, a consumers genetic data. Sensitive
                        Personal Information also means the processing of biometric information for the purpose of
                        uniquely identifying a consumer’s personal information collected and analyzed concerning a
                        consumer’s health, personal information collected and analyzed concerning a consumer’s sex life
                        or sexual orientation.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Exercising Your Rights
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        To exercise your rights described above, please submit a verifiable consumer request (which we
                        explain below) to us by either:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Calling 844-853-1096
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Completing the online <a
                                href={'https://www.andersenwindows.com/support/do-not-sell-my-info/'} target={'_blank'}
                                rel="noreferrer">
                            Privacy Request Form
                            </a>
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        Only you or a person registered with the California Secretary of State that you authorize to act
                        on your behalf may make a verifiable consumer request related to your personal information. You
                        may also make a verifiable consumer request on behalf of your minor child.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        You may only make a verifiable consumer request for access or data portability twice within a
                        12-month period. The verifiable consumer request must:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Provide sufficient information that allows us to reasonably verify you are the person about
                            whom we collected personal information or an authorized representative.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Describe your request with sufficient detail that allows us to properly understand,
                            evaluate, and respond to it.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        We cannot respond to your request or provide you with personal information if we cannot verify
                        your identity or authority to make the request and confirm the personal information relates to
                        you. Making a verifiable consumer request does not require you to create an account with us. We
                        will only use personal information provided in a verifiable consumer request to verify the
                        requestor's identity or authority to make the request.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontStyle: 'italic'}}>
                        Response Timing and Format
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we
                        require more time (up to 90 days), we will inform you of the reason and extension period in
                        writing. If you have an account with us, we will deliver our written response to that account.
                        If you do not have an account with us, we will deliver our written response by mail or
                        electronically, at your option. Any disclosures we provide will only cover the 12-month period
                        preceding the verifiable consumer request's receipt. The response we provide will also explain
                        the reasons we cannot comply with a request, if applicable. For example, we will not provide
                        social security numbers, driver’s license numbers or government-issued identification numbers,
                        financial account numbers, passwords or security questions and answers, or any specific pieces
                        of information if the disclosure presents the possibility of unauthorized access that could
                        result in identity theft or fraud or unreasonable risk to data or systems and network security.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        For data portability requests, we will select a format to provide your personal information that
                        is readily useable and should allow you to transmit the information from one entity to another
                        entity without hindrance.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We do not charge a fee to process or respond to your verifiable consumer request unless it is
                        excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee,
                        we will tell you why we made that decision and provide you with a cost estimate before
                        completing your request.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, textDecoration: 'underline'}}>
                        Non-Discrimination
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We will not discriminate against you for exercising any of your CCPA/CPRA rights. Unless
                        permitted by the CCPA/CPRA, we will not:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Deny you goods or services.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Charge you different prices or rates for goods or services, including through granting
                            discounts or other benefits, or imposing penalties.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Provide you with a different level or quality of goods or services.
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Suggest that you may receive a different price or rate for goods or services or a different
                            level or quality of goods or services.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{mt: 2, textDecoration: 'underline'}}>
                        Changes to Our Privacy Notice
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        We know that our business will continue to evolve, and, as such, from time to time this Privacy
                        Policy may be reviewed and revised. Lead Surge reserves the right to change this Privacy Policy
                        by posting the changes on Renewalbyandersenwindowsanddoors.com. You can determine when this
                        Privacy Policy was last updated by reviewing the Last Updated legend stated at the top of this
                        Privacy Policy and any revisions will take effect upon posting. We will notify our customers of
                        material changes to this Privacy Policy by either sending a notice to the email address you
                        provided to us or by placing a notice on our website. We encourage you to check this Privacy
                        Policy from time to time to review the most current version.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, textDecoration: 'underline'}}>
                        Contact Information
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        If you have any questions or comments about this Privacy Policy, the ways in which we collect
                        and use your personal information, your choices and rights regarding such use, or wish to
                        exercise your rights under California law, please do not hesitate to contact us at:
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, textDecoration: 'underline'}}>
                        LEAD SURGE
                    </Typography>
                    <Typography variant="body1">Attn: Privacy Officer</Typography>
                    <Typography variant="body1">
                        <a href={'mailto:privacy@leadsurgeadvertising.com'}>
                            privacy@leadsurgeadvertising.com
                        </a>
                    </Typography>
                    <Typography variant="body1">1-866-I-OPT-OUT, Service Code 1991</Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        You also have the right to lodge a complaint to the supervisory authority about the way we
                        process your personal data. We would, however, appreciate the chance to deal with your concerns
                        before you approach the supervisory authority, so please contact us in the first instance.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        If you contact us by phone, the conversations may be recorded. You will be notified of these
                        recordings at the beginning of the call. We use these recordings to improve the quality or
                        compliance of our customer service, verify the accuracy of the information you provide, ensure
                        the prevention of fraud and train our teams. We keep these recordings for as long as necessary
                        and then erase them. Your personal data collected during a call is processed in accordance with
                        the provisions of this Privacy Policy.
                    </Typography>
                    <Typography variant="h6">Texting Policy – Part 3</Typography>
                    <Typography variant="body1" sx={{my: 2, textAlign: 'center'}}>
                        ADDITIONAL TERMS & CONDITIONS AND PRIVACY FOR TEXT MARKETING
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        Lead Surge is offering a mobile messaging service (the “Service”), which you agree to use and
                        participate in subject to these Mobile Messaging Terms and Conditions and Privacy Policy (the
                        “Agreement”). By opting in to or participating in our Services, you accept and agree to these
                        Terms and Conditions, including, without limitation, Part 4 of this Privacy Policy which is your
                        agreement to resolve any disputes with us through binding, individual-only arbitration. This
                        Agreement is limited to the Service and is not intended to modify other Terms and Conditions or
                        Privacy Policy that may govern the relationship between you and Us in other contexts.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>User Opt-In:</b> The Service allows Users to receive SMS mobile messages by affirmatively
                        opting into the Service through an online application. By participating in the Service, you
                        agree to receive autodialed or prerecorded marketing mobile messages at the phone number
                        associated with your opt-in, and you understand that consent is not required to make any
                        purchase from Us. While you consent to receive messages sent using an autodialer, the foregoing
                        shall not be interpreted to suggest or imply that any or all of Our mobile messages are sent
                        using an automatic telephone dialing system (“ATDS” or “autodialer”).
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>User Opt-Out:</b> If you do not wish to continue participating in the Service or no longer
                        agree to this Agreement, you agree to reply “STOP”, “END”, “CANCEL”, “UNSUBSCRIBE”, or “QUIT” to
                        any mobile message from Us in order to opt out of the Service. You may receive an additional
                        mobile message confirming your decision to opt-out. You understand and agree that the foregoing
                        options are the only reasonable and valid methods of opting out. You also understand and agree
                        that any other method of opting out, including, but not limited to, texting words other than
                        those set forth above or verbally requesting one of our employees to remove you from our list,
                        is not a reasonable or valid means of opting out.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>Duty to Notify and Indemnify:</b> If at any time you intend to stop using the mobile
                        telephone number that has been used to subscribe to the Service, including canceling your
                        service plan or selling or transferring the phone number to another party, you agree that you
                        will complete the User Opt-Out process set forth above before ending your use of the mobile
                        telephone number. You understand and agree that your agreement to do so is a material part of
                        these Terms and Conditions. You further agree that, if you discontinue the use of your mobile
                        telephone number without notifying Us of such change, you agree that you will be responsible for
                        all costs (including attorneys’ fees) and liabilities incurred by Us, or any party that assists
                        in the delivery of the mobile messages, as a result of claims brought by individual(s) who are
                        later assigned that mobile telephone number. This duty and agreement shall survive any
                        cancellation or termination of your agreement to participate in any of our Services.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        YOU AGREE THAT YOU SHALL INDEMNIFY, DEFEND, AND HOLD US HARMLESS FROM ANY CLAIM OR LIABILITY
                        RESULTING FROM YOUR FAILURE TO NOTIFY US OF A CHANGE IN THE INFORMATION YOU HAVE PROVIDED,
                        INCLUDING ANY CLAIM OR LIABILITY UNDER THE TELEPHONE CONSUMER PROTECTION ACT, 47 U.S.C. § 227,
                        et seq., OR SIMILAR STATE AND FEDERAL LAWS, AND ANY REGULATIONS PROMULGATED THEREUNDER RESULTING
                        FROM US ATTEMPTING TO CONTACT YOU AT THE MOBILE TELEPHONE NUMBER YOU PROVIDED.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>Service Description:</b> Without limiting the scope of the Service, users that opt into the
                        Service can expect to receive messages concerning the marketing and sale of RbA home improvement
                        and remodeling products, services, and related software products and services.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>Cost and Frequency:</b> Message and data rates may apply. The Service involves recurring
                        mobile messages, and additional mobile messages may be sent periodically based on your
                        interaction(s) with Us.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>Support Instructions:</b> For support regarding the Service, text “HELP” to the number you
                        received text messages from or email us at <a
                            href={'mailto:privacy@leadsurgeadvertising.com'}>privacy@leadsurgeadvertising.com</a>.
                        Please note contacting Us at this email address is not a reasonable or valid method of opting
                        out of the Service. Opt-outs must be submitted in accordance with the procedures set forth
                        above.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>Our Disclaimer of Warranty:</b> The Service is offered on an "as-is" basis and may not be
                        available in all areas at all times and may not continue to work in the event of product,
                        software, coverage, or other changes made by your wireless carrier. We will not be liable for
                        any delays or failures in the receipt of any mobile messages connected with this Service.
                        Delivery of mobile messages is subject to effective transmission from your wireless service
                        provider/network operator and is outside of Our control.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>Participant Requirements:</b> You must have a wireless device of your own, be capable of
                        two-way messaging, be using a participating wireless carrier, and be a wireless service
                        subscriber with text messaging service. Not all cellular phone providers carry the necessary
                        service to participate. Check your phone capabilities for specific text-messaging instructions.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>Age Restriction:</b> You may not use or engage with the Platform if you are under thirteen
                        (13) years of age. If you use or engage with the Platform and are between the ages of thirteen
                        (13) and eighteen (18) years of age, you must have your parent’s or legal guardian’s permission
                        to do so. By using or engaging with the Platform, you acknowledge and agree that you are not
                        under the age of thirteen (13) years, are between the ages of thirteen (13) and eighteen (18)
                        and have your parent’s or legal guardian’s permission to use or engage with the Platform, or are
                        of adult age in your jurisdiction. By using or engaging with the Platform, you also acknowledge
                        and agree that you are permitted by your jurisdiction’s applicable law to use and/or engage with
                        the Platform.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2}}>
                        <b>Prohibited Content:</b> You acknowledge and agree to not send any prohibited content over the
                        Platform. Prohibited content includes:
                    </Typography>
                    <List sx={{listStyleType: 'disc', listStylePosition: 'inside'}}>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Any fraudulent, libelous, defamatory, scandalous, threatening, harassing, or stalking
                            activity;
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Objectionable content, including profanity, obscenity, lasciviousness, violence, bigotry,
                            hatred, and discrimination on the basis of race, sex, religion, nationality, disability,
                            sexual orientation, or age;
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Pirated computer programs, viruses, worms, Trojan horses, or other harmful code;
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Any product, service, or promotion that is unlawful where such product, service, or
                            promotion thereof is received;
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Any content that implicates and/or references personal health information that is protected
                            by the Health Insurance Portability and Accountability Act (“HIPAA”) or the Health
                            Information Technology for Economic and Clinical Health Act (“HITEC” Act); and
                        </ListItem>
                        <ListItem sx={{display: 'list-item', ml: 2}} disablePadding={true}>
                            Any other content that is prohibited by applicable law in the jurisdiction from which the
                            message is sent.
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>Miscellaneous:</b> You warrant and represent to Us that you have all necessary rights, power,
                        and authority to agree to these Terms and Conditions and perform your obligations hereunder, and
                        nothing contained in this Agreement or the performance of such obligations will place you in
                        breach of any other contract, agreement, or obligation. The failure of either party to exercise,
                        in any respect, any right provided for herein will not be deemed a waiver of any further rights
                        hereunder. If any provision of this Agreement is found to be unenforceable or invalid, that
                        provision will be limited or eliminated to the extent necessary so that this Agreement otherwise
                        will remain in full force, effect, and enforceable. Any new features, changes, updates, or
                        improvements of the Service shall be subject to this Agreement unless explicitly stated
                        otherwise in writing. We reserve the right to change this Agreement from time to time. Any
                        updates to this Agreement shall be communicated to you. You acknowledge your responsibility to
                        review this Agreement from time to time and to be aware of any such changes. By continuing to
                        participate in the Service after any such changes, you accept this Agreement, as modified.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2}}>
                        <b>Texting Privacy:</b> We will only use the information you provide through the Service to
                        transmit your mobile messages and respond to you, if necessary. This includes, but is not
                        limited to, sharing information with platform providers, phone companies, and other vendors who
                        assist us in the delivery of mobile messages. WE DO NOT SELL, RENT, LOAN, TRADE, LEASE, OR
                        OTHERWISE TRANSFER FOR PROFIT ANY PHONE NUMBERS OR CUSTOMER INFORMATION COLLECTED THROUGH THE
                        SERVICE TO ANY THIRD PARTY OTHER THAN RBA. Nonetheless, We reserve the right at all times to
                        disclose any information as necessary to satisfy any law, regulation, or governmental request,
                        to avoid liability, or to protect Our rights or property. When you complete forms online or
                        otherwise provide Us with information in connection with the Service, you agree to provide
                        accurate, complete, and true information. You agree not to use a false or misleading name or a
                        name that you are not authorized to use. If, in Our sole discretion, We believe that any such
                        information is untrue, inaccurate, or incomplete, or you have opted into the Service for an
                        ulterior purpose, We may refuse you access to the Service and pursue any appropriate legal
                        remedies.
                    </Typography>
                    <Typography variant="h6" id={'dispute-resolution-policy'}>
                        Dispute Resolution Policy – Part 4
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        NOTICE: BY VISITING AND/OR USING THIS WEB SITE AND SUBMITTING ANY INFORMATION THROUGHY OR VIA
                        THIS WEB SITE, YOU ARE AGREEING TO HAVE ANY DISPUTE ARISING OUT OF THE MATTERS INCLUDED IN THE
                        ‘ARBITRATION OF DISPUTES’ PROVISION DECIDED BY NEUTRAL ARBITRATION AS PROVIDED BY CALIFORNIA LAW
                        AND YOU ARE GIVING UP ANY RIGHTS YOU MIGHT POSSESS TO HAVE THE DISPUTE LITIGATED IN A COURT OR
                        JURY TRIAL. YOU ARE GIVING UP YOUR JUDICIAL RIGHTS TO DISCOVERY AND APPEAL, UNLESS THOSE RIGHTS
                        ARE SPECIFICALLY INCLUDED IN THE ‘ARBITRATION OF DISPUTES’ PROVISION. IF YOU REFUSE TO SUBMIT TO
                        ARBITRATION AFTER AGREEING TO THIS PROVISION, YOU MAY BE COMPELLED TO ARBITRATE UNDER THE
                        AUTHORITY OF THE BUSINESS AND PROFESSIONS CODE OR OTHER APPLICABLE LAWS.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        Going forward, in this Part 4 “We” refers to (i) you, as the visitor to this web site, (ii) Lead
                        Surge, and (iii) RbA, together. “I” refers to you, as the visitor to this web site.
                    </Typography>
                    <Typography variant="body1"
                        sx={{my: 2, fontWeight: 600, textDecoration: 'underline', textAlign: 'center'}}>
                        ARBITRATION OF DISPUTES
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        WE AGREE THAT ANY AND ALL DISPUTES, CLAIMS, OR CONTROVERSIES (HEREAFTER REFERRED TO AS A
                        “CLAIM”) ARISING UNDER OR RELATING TO MY USE OF (I) THIS WEB SITE, (II) MY ENTERING OF
                        INFORMATION OR DATA INTO THE FORMS CONTAINED ON THIS WEB SITE, OR (III) ANY REPLACEMENT WINDOW
                        AGREEMENT BETWEEN ME AND RENEWAL BY ANDERSEN AND/OR ITS AUTHORIZED DEALERS (“OUR AGREEMENT”),
                        INCLUDING BY WAY OF EXAMPLE AND NOT AS A LIMITATION: (I) THE RELATIONSHIPS RESULTING FROM OUR
                        AGREEMENT, CALLS, TEXTS OR EMAILS MADE TO ME OR SENT TO ME AND ANY THE WORK AND TRANSACTIONS
                        ARISING AS A RESULT THEREOF INCLUDING ANY FEDERAL OR STATE STATUTORY OR REGULATORY CLAIMS; (II)
                        THE BREACH OR ALLEGED BREACH OF OUR AGREEMENT; OR (III) THE VALIDITY OF OUR AGREEMENT OR THE
                        VALIDITY OR ENFORCEABILITY OF THIS ARBITRATION OF DISPUTES PROVISION (“PROVISION”), SHALL BE
                        SUBJECT TO ARBITRATION IN ACCORDANCE WITH THIS PROVISION.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        I AGREE THAT I WILL ASSERT A CLAIM ONLY ON BEHALF OF MY OWN SELF AND THAT I WILL NOT ASSERT A
                        CLAIM ON BEHALF OF, OR AS A MEMBER OF, A CLASS OR GROUP IN EITHER AN ARBITRATION PROCEEDING, A
                        PRIVATE ATTORNEY GENERAL ACTION OR IN ANY OTHER FORUM OR ACTION. NOTWITHSTANDING ANY OTHER
                        LANGUAGE IN THIS PROVISION, ONLY A COURT, NOT AN ARBITRATOR, WILL DECIDE CLAIMS ABOUT THE
                        VALIDITY, ENFORCEABILITY, COVERAGE OR SCOPE OF THIS PROVISION OR ANY PART OF THIS PROVISION.
                        HOWEVER, ANY CLAIM THAT CONCERNS THE VALIDITY OR ENFORCEABILITY OF OUR AGREEMENT AS A WHOLE IS
                        FOR THE ARBITRATOR, NOT A COURT, TO DECIDE. IF A COURT DETERMINES THAT THIS PROVISION IS NOT
                        FULLY ENFORCEABLE, THE COURT’S DETERMINATION SHALL BE SUBJECT TO APPEAL. THIS PROVISION DOES NOT
                        APPLY TO ANY LAWSUIT OR ADMINISTRATIVE PROCEEDING FILED AGAINST CONTRACTOR BY A STATE OR FEDERAL
                        GOVERNMENT AGENCY EVEN WHEN SUCH AGENCY IS SEEKING RELIEF ON BEHALF OF A CLASS OF CONSUMERS.
                        THIS MEANS THAT CONTRACTOR WILL NOT HAVE THE RIGHT TO COMPEL ARBITRATION OF ANY CLAIM BROUGHT BY
                        SUCH AN AGENCY.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        ANY CLAIM MAY, AT THE OPTION OF EITHER CONTRACTOR OR MYSELF, BE ADJUDICATED BY FINAL AND BINDING
                        ARBITRATION BY ONE ARBITRATOR IN ACCORDANCE WITH THE CODE OF PROCEDURE OF THE FORUM (“FORUM”) IN
                        EFFECT AT THE TIME THE DEMAND FOR ARBITRATION IS MADE. NOTICE OF THE DEMAND FOR ARBITRATION
                        SHALL BE FILED WITH FORUM BY THE PARTY ASSERTING THE CLAIM, AND THE DEMAND SHALL BE COPIED TO
                        THE OTHER PARTY TO OUR AGREEMENT. FURTHER INFORMATION MAY BE OBTAINED AND CLAIMS MAY BE FILED AT
                        ANY OFFICE OF FORUM, WWW.ADRFORUM.COM, OR BY MAIL AT 6465 WAYZATA BLVD., SUITE 480 MINNEAPOLIS,
                        MN 55426 ATTN: CASE COORDINATOR. IF FORUM IS UNABLE OR UNWILLING TO ARBITRATE THE CLAIM, THE
                        PARTIES SHALL UTILIZE JAMS, 620 EIGHTH AVENUE, 34TH FLOOR, NEW YORK, NY 10018, WWW.JAMSADR.COM,
                        (800) 352-5267. IF JAMS IS UNABLE OR UNWILLING TO ARBITRATE THE CLAIM, THEN THE COURT MAY
                        APPOINT AN ARBITRATOR.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        THE DEMAND FOR ARBITRATION SHALL BE MADE BY THE PARTY ASSERTING OR COMPELLING THE ARBITRATION
                        WITHIN A REASONABLE TIME AFTER THE CLAIM IN QUESTION HAS ARISEN, AND IN NO EVENT SHALL THE
                        DEMAND BE MADE AFTER THE DATE WHEN INSTITUTION OF LEGAL OR EQUITABLE PROCEEDINGS BASED ON SUCH
                        CLAIM WOULD BE BARRED BY THE APPLICABLE STATUTE OF LIMITATIONS. IF A PARTY FILES A LAWSUIT IN
                        COURT ASSERTING CLAIM(S) THAT ARE SUBJECT TO ARBITRATION AND THE OTHER PARTY FILES A MOTION WITH
                        THE COURT TO COMPEL ARBITRATION, WHICH IS GRANTED, IT WILL BE THE RESPONSIBILITY OF THE PARTY
                        ASSERTING THE CLAIM(S) TO COMMENCE THE ARBITRATION PROCEEDING. THE ARBITRATION SHALL BE HELD AND
                        ARBITRATED IN THE COUNTY AND STATE IN WHICH I RESIDED DURING MY WORK WITH CONTRACTOR.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        THE SALE TRANSACTIONS AND THE FINANCE TRANSACTIONS (IF ANY) UNDER OUR AGREEMENT INVOLVE
                        INTERSTATE COMMERCE AND ARE GOVERNED BY THE FEDERAL ARBITRATION ACT (“FAA”). ACCORDINGLY, THIS
                        PROVISION IS GOVERNED BY THE FAA, 9 U.S.C. SECTIONS 1 ET SEQ. THE APPOINTED ARBITRATOR MUST BE A
                        LAWYER WITH AT LEAST TEN YEARS OF LEGAL EXPERIENCE. ONCE APPOINTED, THE ARBITRATOR MUST APPLY
                        THE SAME FEDERAL LAW OR THE LAW OF THE STATE IN WHICH THE SERVICES WERE PRIMARILY RENDERED FOR
                        SUBSTANTIVE LAW AND LAW OF REMEDIES AND LEGAL PRINCIPLES, CONSISTENT WITH THE FAA, THAT WOULD
                        APPLY IN COURT, BUT MAY USE DIFFERENT PROCEDURAL RULES. PARTICIPATION BY ANY PARTY IN THE
                        ARBITRATION MAY TAKE PLACE BY TELEPHONE. IF THE ARBITRATION FORUM’S RULES CONFLICT WITH THIS
                        PROVISION, THIS PROVISION WILL CONTROL.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        ANY CLAIM MAY, AT THE OPTION OF THE PARTY RECEIVING THE DEMAND FOR ARBITRATION (THE PARTY NOT
                        ASSERTING THE CLAIM) BE FIRST SUBMITTED TO A NON-BINDING MEDIATION PROCESS GOVERNED BY THE
                        THEN-APPLICABLE RULES OF MEDIATION ESTABLISHED BY THE ARBITRATION FORUM. THE MEDIATION SHALL BE
                        ELECTED WITHIN SEVEN DAYS OF RECEIPT OF THE DEMAND FOR ARBITRATION AND COMPLETED WITHIN THIRTY
                        DAYS THEREAFTER. THE MEDIATION ITSELF SHALL NOT LAST MORE THAN FOUR HOURS, AND THE COSTS OF THE
                        MEDIATION, OTHER THAN LEGAL FEES, WHICH ARE TO BE BORNE BY EACH PARTY, SHALL BE PAID ENTIRELY BY
                        THE PARTY ELECTING THE MEDIATION.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        THE PARTIES SHALL SHARE EQUALLY IN ANY APPLICABLE FILING FEES AND COSTS OF THE ARBITRATION,
                        UNLESS I CAN REASONABLY ESTABLISH TO CONTRACTOR THAT I AM FINANCIALLY BURDENED BY PAYING THE
                        INITIAL CASE OR FILING FEES OF THE ARBITRATION, IN WHICH CASE, CONTRACTOR SHALL BE RESPONSIBLE
                        FOR THE INITIAL CASE OR FILING FEES. THE FINDINGS OF THE ARBITRATOR SHALL BE FINAL AND BINDING
                        ON ALL PARTIES TO THIS AGREEMENT, AND MAY INCLUDE AN AWARD OR REIMBURSEMENT OF FILING FEES THAT
                        HAVE BEEN PAID BY ONE PARTY OR THE OTHER. OTHER THAN AS REQUIRED BY LAW OR AS DETERMINED BY THE
                        ARBITRATOR IN ACCORDANCE WITH APPLICABLE LAW, EACH PARTY SHALL BE RESPONSIBLE FOR ITS OWN LEGAL
                        FEES.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        ANY ARBITRATION PROCEEDING BROUGHT UNDER THIS PROVISION, AND ANY AWARD, FINDING, OR VERDICT OF
                        OR FROM SUCH PROCEEDING SHALL REMAIN CONFIDENTIAL BETWEEN THE PARTIES AND SHALL NOT BE MADE
                        PUBLIC. WE SHALL ALLOW AND PARTICIPATE IN DISCOVERY IN ACCORDANCE WITH THE FEDERAL RULES OF
                        CIVIL PROCEDURE FOR A LIMITED PERIOD OF NINETY (90) DAYS AFTER THE FILING OF THE ANSWER OR OTHER
                        RESPONSIVE PLEADING. UNRESOLVED DISCOVERY DISPUTES MAY BE BROUGHT TO THE ATTENTION OF, AND MAY
                        BE DISPOSED BY, THE ARBITRATOR. EITHER CONTRACTOR OR I MAY BRING AN ACTION IN ANY COURT OF
                        COMPETENT JURISDICTION, IF NECESSARY, TO COMPEL ARBITRATION UNDER THIS PROVISION, TO OBTAIN
                        PRELIMINARY RELIEF IN SUPPORT OF A CLAIM TO BE ADJUDICATED BY ARBITRATION, OR TO ENFORCE AN
                        ARBITRATION AWARD. A JUDGMENT UPON ANY AWARD RENDERED BY THE ARBITRATOR MAY BE ENTERED IN ANY
                        COURT HAVING JURISDICTION. IF ANY TERM OR CLAUSE OF THIS PROVISION IS FOUND TO BE UNENFORCEABLE
                        OR IN VIOLATION OF APPLICABLE STATE LAW, WE SHALL TREAT THIS PROVISION AS IF THAT TERM OR CLAUSE
                        DID NOT EXIST, AND THE REMAINDER OF THIS PROVISION SHALL REMAIN IN FULL FORCE AND EFFECT, OTHER
                        THAN AS DISCUSSED PREVIOUSLY IN REGARD TO THE WAIVER OF CLASS OR REPRESENTATIVE ACTIONS BEING
                        APPEALABLE.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        NO ARBITRATION PROCEEDING BROUGHT UNDER THIS PROVISION SHALL INCLUDE BY CONSOLIDATION, JOINDER
                        OR IN ANY OTHER MANNER ANY OTHER PERSON OR ENTITY WHO IS NOT A PARTY TO THIS AGREEMENT UNLESS
                        (I) THE INCLUSION OF SUCH PERSON OR ENTITY IS NECESSARY IF COMPLETE RELIEF IS TO BE AFFORDED
                        AMONG THOSE WHO ARE ALREADY PARTIES TO THE ARBITRATION, AND/OR SUCH OTHER PERSON OR ENTITY IS
                        SUBSTANTIALLY INVOLVED IN A QUESTION OF LAW OR FACT THAT IS COMMON TO THOSE WHO ARE ALREADY
                        PARTIES TO THE ARBITRATION AND THAT WILL ARISE IN SUCH PROCEEDING; AND (II) THE WRITTEN CONSENT
                        OF THE OTHER PERSON OR ENTITY SOUGHT TO BE INCLUDED AND THE WRITTEN CONSENT OF EACH PARTY TO
                        THIS AGREEMENT HAS BEEN OBTAINED FOR SUCH INCLUSION.
                    </Typography>
                    <Typography variant="body1" sx={{my: 2, fontWeight: 600}}>
                        WE ARE HEREBY AGREEING TO CHOOSE ARBITRATION, RATHER THAN LITIGATION OR SOME OTHER MEANS OF
                        DISPUTE RESOLUTION TO ADDRESS OUR GRIEVANCES OR ALLEGED GRIEVANCES WITH THE EXPECTATION THAT
                        THIS RESOLUTION PROCESS MAY BE MORE COST-EFFECTIVE AND EXPEDIENT FOR THE PARTIES THAN
                        LITIGATION. BY ENTERING INTO THIS AGREEMENT AND THE ARBITRATION PROVISIONS OF THIS SECTION, BOTH
                        PARTIES ARE GIVING UP THEIR CONSTITUTIONAL RIGHT TO HAVE ANY DISPUTE DECIDED IN A COURT OF LAW
                        BEFORE A JURY, AND INSTEAD ARE ACCEPTING THE USE OF ARBITRATION, EXCEPT THAT IF THERE IS A SMALL
                        CLAIMS COURT (OR AN EQUIVALENT TYPE OF COURT) LOCATED WITHIN THE COUNTY AND STATE IN WHICH I
                        RESIDE, THEN I MAY, IN ACCORDANCE WITH THE RULES OF THAT SMALL CLAIMS COURT, CHOOSE TO BRING
                        (AND MUST THEN KEEP) MY OWN CLAIM IN THAT SMALL CLAIMS COURT.
                    </Typography>
                </>
                </Box>
            </Grid>
        </Grid>
    </>;
};

export default Privacy;
