import {zodResolver} from '@hookform/resolvers/zod';
import {Box, useTheme} from '@mui/material';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {RhfTextField} from 'mui-rhf-integration';
import type {Dispatch, SetStateAction} from 'react';
import ReCAPTCHA, {type ReCAPTCHAProps} from 'react-google-recaptcha';
import {useForm} from 'react-hook-form';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import {z} from 'zod';
import QuizNavigation from '@/components/QuizNavigation';
import {useInvalidZipMutation} from '@/mutations/hive';
import {useZipLookupMutation} from '@/mutations/zip';
import type {LeadToken} from '@/types/lead-token';
import {errorMap} from '@/utils/zod';

type Props = {
    leadToken : LeadToken;
    setCurrentStepIndex : Dispatch<SetStateAction<number>>;
    setBackOverride : Dispatch<SetStateAction<boolean>>;
};

const schema = z.object({
    zip: z.string().trim().min(5).regex(/^\d{5}(?:[-\s]\d{4})?$/),
    captchaToken: z.string(),
});

type FormValues = z.infer<typeof schema>;

const StyledReCAPTCHA = styled(ReCAPTCHA)<ReCAPTCHAProps>(() => ({
    '> div > div': {marginLeft: 'auto', marginRight: 'auto'},
}));

const QuizZipStep = ({leadToken, setCurrentStepIndex, setBackOverride} : Props) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const zipLookupMutation = useZipLookupMutation(leadToken);
    const invalidZipMutation = useInvalidZipMutation();

    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: {
            zip: leadToken.zip ?? undefined,
        },
    });

    const onChange = (captchaToken : string | null) => {
        form.setValue('captchaToken', captchaToken as string, {shouldValidate: true});
    };

    const handleSubmit = (values : FormValues) => {
        zipLookupMutation.mutate(values, {
            onSuccess: () => {
                setCurrentStepIndex(5);
            },
            onError: () => {
                // invalid zip should be handled by the zipLookup API endpoint to avoid another HTTP call
                invalidZipMutation.mutate(leadToken.id);

                navigate({
                    pathname: '/out-of-territory',
                    search: `?${createSearchParams(
                        Object.fromEntries(searchParams)
                    ).toString()}`,
                });
            },
        });
    };

    return <Grid
        container
        size={{xs: 12}}
        component={'form'}
        name={'quiz_step_zip'}
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate
    >
        <Grid size={{xs: 12}} sx={{py: 2, textAlign: 'center'}}>
            <Typography variant={'h4'} sx={{fontWeight: 400}}>
                Enter Zip Code For Special Local Offer
            </Typography>
        </Grid>
        <Grid size={{xs: 12}}>
            <Box textAlign={'center'}>
                <RhfTextField
                    control={form.control}
                    name={'zip'}
                    label={'ZIP CODE'}
                    fullWidth={isMobile}
                />
                <StyledReCAPTCHA
                    sitekey={
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY
                    }
                    onChange={onChange}
                    sx={{
                        marginTop: 2,
                    }}
                />
                <Box
                    sx={{
                        mt: 2,
                        display: form.getFieldState('captchaToken').error ? 'flex' : 'none',
                        justifyContent: 'center',
                        color: 'red',
                        fontWeight: 800,
                    }}
                >
                    Captcha is Required
                </Box>
            </Box>
        </Grid>
        <QuizNavigation
            buttonText={'Next Question'}
            disabled={!form.formState.isValid}
            stepNumber={4}
            setCurrentStepIndex={setCurrentStepIndex}
            setBackOverride={setBackOverride}
        />
    </Grid>;
};

export default QuizZipStep;
