import {useTheme} from '@mui/material';
import Grid from '@mui/material/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {type Dispatch, type SetStateAction, useEffect, useRef} from 'react';
import QuizOfferStep from '@/components/QuizOfferStep';
import QuizProgressBar from '@/components/QuizProgressBar';
import QuizStep from '@/components/QuizStep';
import QuizZipStep from '@/components/QuizZipStep';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import type {QuizStepType} from '@/types/quiz';
import Typography from "@mui/material/Typography";

type Props = {
    leadToken : LeadToken;
    currentStep : QuizStepType;
    currentStepIndex : number;
    setCurrentStepIndex : Dispatch<SetStateAction<number>>;
    setBackOverride : Dispatch<SetStateAction<boolean>>;
};

const Quiz = (
    {leadToken, currentStep, currentStepIndex, setCurrentStepIndex, setBackOverride} : Props
) : React.ReactElement => {
    const eventMutation = useCreateLeadTokenEventMutation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'quiz'});
        }
    }, [isSectionVisible]);

    return <Grid container size={{xs: 12}} sx={{mb: 2}}>
        <Grid size={{xs: 12}} sx={{
            my: 2,
            textAlign: 'center',
        }}>
            <Typography variant={'h4'} ref={ref}>
                Take 30 seconds to tell us about your home
            </Typography>
        </Grid>
        <Grid size={{xs: 12}} sx={{
            mt: '15px',
            textAlign: 'center',
        }}>
            <QuizProgressBar stepNumber={currentStepIndex}/>
        </Grid>
        <Grid size={{xs: 12}} sx={{
            width: isMobile ? '100%' : '50%',
            margin: isMobile ? 2 : 'auto',
        }}>
            {currentStepIndex < 4 && <QuizStep
                leadToken={leadToken}
                step={currentStep}
                setCurrentStepIndex={setCurrentStepIndex}
                key={currentStep.stepNumber}
                setBackOverride={setBackOverride}
            />}
            {currentStepIndex === 4 && <QuizZipStep
                leadToken={leadToken}
                setCurrentStepIndex={setCurrentStepIndex}
                setBackOverride={setBackOverride}
            />}
            {currentStepIndex === 5 && <QuizOfferStep
                leadToken={leadToken}
                setCurrentStepIndex={setCurrentStepIndex}
                setBackOverride={setBackOverride}
            />}
        </Grid>
    </Grid>;
};

export default Quiz;
