import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Container, Radio, useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import BA1 from '@/assets/before-after-photos/ba_awning_1.png';
import BA2 from '@/assets/before-after-photos/ba_bay-window_1.jpg';
import BA3 from '@/assets/before-after-photos/ba_dh-window_1.jpg';
import BA4 from '@/assets/before-after-photos/ba_entry-door_1.jpg';
import BA5 from '@/assets/before-after-photos/ba_entry-door_2.jpg';
import BA6 from '@/assets/before-after-photos/ba_patio-door_1.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CarouselButton from '@/components/CarouselButton';
import useOnScreen from '@/hooks/useOnScreen';

const items = [
    {image: BA1, alt: 'before and after'},
    {image: BA2, alt: 'before and after'},
    {image: BA3, alt: 'before and after'},
    {image: BA4, alt: 'before and after'},
    {image: BA5, alt: 'before and after'},
    {image: BA6, alt: 'before and after'},
];

type ItemProps = {
    itemNumber : number;
};

const Item = ({itemNumber} : ItemProps) => {
    return <Box
        component='img'
        src={items[itemNumber].image}
        alt={items[itemNumber].alt}
        key={`carousel-item-${itemNumber}`}
        sx={{
            p: 1,
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            borderRadius: 8,
        }}
    >
    </Box>;
};

const DiscoverCarousel = () : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const sliderRef = useRef<Slider>(null);
    const boxRef = useRef<HTMLElement>(null);
    const isVisible = useOnScreen(boxRef);
    const [checkedDot, setCheckedDot] = useState(0);
    const [settings, setSettings] = useState({
        centerMode: true,
        infinite: true,
        centerPadding: '20%',
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        dots: !mobile,
        arrows: false,
        beforeChange: (_prev : number, next : number) => {
            setCheckedDot(next);
        },
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        customPaging: (i : number) => <a><Radio key={`dot-${i}`} value={i} checked={checkedDot === i}/></a>,
    });

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                if (sliderRef.current) {
                    sliderRef.current.slickPlay();
                }
            }, 3500);
        }
    }, [isVisible]);

    useEffect(() => {
        if (sliderRef.current) {
            setSettings({
                ...settings,
                customPaging: (i : number) => {
                    return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a>
                            <Radio key={`dot-${i}`} value={i} checked={checkedDot === i}/>
                        </a>
                    );
                },
            });
        }
    }, [checkedDot]);

    return (
        <Container
            maxWidth={false}
            sx={{
                mb: mobile ? 1 : 5,
                pb: mobile ? 2 : 5,
                position: 'relative',
            }}
        >
            <Box ref={boxRef}>
                <Slider {...settings} ref={sliderRef}>
                    {
                        items.map((_item, i) => <Item key={i} itemNumber={i}/>)
                    }
                </Slider>
            </Box>
            {!mobile && <><CarouselButton
                onClick={() => {
                    if (sliderRef.current) {
                        sliderRef.current.slickPrev();
                    }
                }}
                sx={{
                    top: 'calc(50% - 20px - 20px)',
                    left: {xs: '30px', sm: '60px'},
                }}
            >
                <ArrowBackIcon sx={{color: 'green'}}/>
            </CarouselButton>
            <CarouselButton
                onClick={() => {
                    if (sliderRef.current) {
                        sliderRef.current.slickNext();
                    }
                }}
                sx={{
                    top: 'calc(50% - 20px - 20px)',
                    right: {xs: '30px', sm: '60px'},
                }}
            >
                <ArrowForwardIcon sx={{color: 'green'}}/>
            </CarouselButton></>}
        </Container>
    );
};

export default DiscoverCarousel;
