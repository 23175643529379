import {Button, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import {useEffect, useRef} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import {useLsrcPhoneNumberQuery} from '@/queries/lsrc';
import type {LeadToken} from '@/types/lead-token';
import {defaultPhoneNumber} from "@/components/PhoneButton.tsx";

export  const scrollWithOffset = (el : HTMLElement) => window.scrollTo({ top: el.getBoundingClientRect().top + window.pageYOffset - 150, behavior: 'smooth' });

const CtaSection = ({leadToken, headerText} : {leadToken : LeadToken, headerText: string}) : React.ReactElement => {
    const phoneNumberQuery = useLsrcPhoneNumberQuery(leadToken.sourceId);
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'hero_section'});
        }
    }, [isSectionVisible]);

    return <Grid container size={{xs: 12}}>
        <Grid size={{xs: 12}} sx={{pt: 2}}>
            <Stack alignItems={'center'} textAlign={'center'} my={5}>
                <Typography variant={'h3'} px={5} sx={{
                    fontSize: {
                        xs: 'clamp(2rem, 2.5vw, 2rem)',
                        sm: 'clamp(2rem, 2.5vw, 2rem)',
                        md: 'clamp(2.5rem, 2.5vw, 2.5rem)',
                        lg: 'clamp(3rem, 2.5vw, 3rem)',
                    },
                }} ref={ref}>
                    {headerText}
                </Typography>
                <a href={'tel:' + (phoneNumberQuery.data ?? defaultPhoneNumber)} style={{color: '#001722'}}>
                    <Button sx={{
                        color: '#000',
                        bgcolor: '#f9ac4d',
                        borderRadius: 6,
                        px: 8,
                        py: 1,
                        '&.MuiButtonBase-root:hover': {
                            bgcolor: '#f9ac4d',
                        },
                        mt: 5,
                        mb: 3,
                        fontSize: {
                            xs: 'clamp(1rem, 2.5vw, 1rem)',
                            sm: 'clamp(1rem, 2.5vw, 1rem)',
                            md: 'clamp(1rem, 2.5vw, 1rem)',
                            lg: 'clamp(1.75rem, 2.5vw, 1.75rem)',
                        },
                    }}>
                        Call Now: {phoneNumberQuery.data ?? defaultPhoneNumber}
                    </Button>
                </a>

                {/*<HashLink smooth scroll={el => el.scrollIntoView({behavior: 'smooth', block: 'start'})} to={{*/}
                <HashLink smooth scroll={el => scrollWithOffset(el)} to={{
                    pathname: '/',
                    search: `?${createSearchParams(
                        Object.fromEntries(searchParams)
                    ).toString()}`,
                    hash: '#quiz-container',
                }}>
                    <Typography sx={{
                        fontSize: {
                                xs: 'clamp(1rem, 2.5vw, 1rem)',
                                sm: 'clamp(1rem, 2.5vw, 1rem)',
                                md: 'clamp(1.5rem, 2.5vw, 1.5rem)',
                            },
                        textDecoration: 'underline',
                        color: '#000',
                        }}>
                        or answer 3 quick questions
                    </Typography>
                </HashLink>
            </Stack>
        </Grid>
    </Grid>;
};

export default CtaSection;
