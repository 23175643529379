import {Box, Divider, ImageList, ImageListItem, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import TagManager from 'react-gtm-module';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import WhiteSwatch from 'src/assets/color-swatches/white.png';
import TerratoneSwatch from 'src/assets/color-swatches/terratone.png';
import SandtoneSwatch from 'src/assets/color-swatches/sandtone.png';
import DarkBronzeSwatch from 'src/assets/color-swatches/dark-bronze.png';
import CanvasSwatch from 'src/assets/color-swatches/canvas.png';
import BlackSwatch from 'src/assets/color-swatches/black.png';
import PineSwatch from 'src/assets/color-swatches/pine.png';
import OakSwatch from 'src/assets/color-swatches/oak.png';
import MapleSwatch from 'src/assets/color-swatches/maple.png';
import RedRockSwatch from 'src/assets/color-swatches/red-rock.png';
import ForestGreenSwatch from 'src/assets/color-swatches/forest-green.png';
import CocoaBeanSwatch from 'src/assets/color-swatches/cocoa-bean.png';

const interiorColors = [
    {img: WhiteSwatch, title: 'White'},
    {img: TerratoneSwatch, title: 'Terratone'},
    {img: SandtoneSwatch, title: 'Sandtone'},
    {img: DarkBronzeSwatch, title: 'Dark Bronze'},
    {img: CanvasSwatch, title: 'Canvas'},
    {img: BlackSwatch, title: 'Black'},
    {img: PineSwatch, title: 'Pine'},
    {img: OakSwatch, title: 'Oak'},
    {img: MapleSwatch, title: 'Maple'},
];

const exteriorColors = [
    {img: WhiteSwatch, title: 'White'},
    {img: TerratoneSwatch, title: 'Terratone'},
    {img: SandtoneSwatch, title: 'Sandtone'},
    {img: RedRockSwatch, title: 'Red Rock'},
    {img: ForestGreenSwatch, title: 'Forest Green'},
    {img: DarkBronzeSwatch, title: 'Dark Bronze'},
    {img: CocoaBeanSwatch, title: 'Cocoa Bean'},
    {img: CanvasSwatch, title: 'Canvas'},
    {img: BlackSwatch, title: 'Black'},
];

const SwatchPanel = ({swatches, title, isMobile} : {swatches: {img: string, title: string}[], title: string, isMobile: boolean}) => {
    return <Grid container size={{xs: 12, md: 6}} sx={{justifyContent: 'center', textAlign: 'center'}}>
        <Stack>
            <Typography variant={'h5'}>{title}</Typography>
            <ImageList
                cols={3}
                gap={isMobile ? 10 : 30}
                sx={{overflowY: 'visible'}}
            >
                {swatches.map(swatch => (
                    <ImageListItem sx={{textAlign: 'center'}}>
                        <Box
                            component={'img'}
                            alt="Renewal by Anderson"
                            src={swatch.img}
                        />
                        <Typography sx={{
                            fontSize: isMobile ? 12 : 24,
                            fontWeight: 500,
                            pt: isMobile ? 0 : 3,
                        }}>
                            {swatch.title}
                        </Typography>
                    </ImageListItem>
                ))}
            </ImageList>
        </Stack>
    </Grid>
}

const FrameColors = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'frame_colors',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'frame_colors'});
        }
    }, [isSectionVisible]);

    return <Grid container size={{xs: 12}} sx={{justifyContent: 'center', backgroundColor: '#eef2f9', pb: 5}} spacing={0}>
        <Grid container size={{xs: 12}} sx={{justifyContent: 'center', mt: isMobile ? 5 : 10}}>
            <Typography variant={'h4'} ref={ref} sx={{
                fontSize: "clamp(1.5rem, 2.5vw, 3rem)",
                fontWeight: 700,
            }}>Colors to match your home and style</Typography>
        </Grid>
        <Grid container size={{xs: 12}} sx={{justifyContent: 'center', textAlign: 'center', my: 2, mx: 2}}>
            <Typography sx={{fontSize: 22}}>
                Fibrex material window frames come in several colors and are available in 49 color
                combinations.
            </Typography>
        </Grid>
        <Divider sx={{width: '90%', my: 2}}/>
        <SwatchPanel
            swatches={interiorColors}
            title={'Interior Colors & Woods'}
            isMobile={isMobile}
        />
        <SwatchPanel
            swatches={exteriorColors}
            title={'Exterior Colors'}
            isMobile={isMobile}
        />
    </Grid>;
};

export default FrameColors;
