import {Box, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import TagManager from 'react-gtm-module';
import FibrexLogo from '@/assets/fibrex_logo.png';
import UsaBuiltLogo from '@/assets/usa_built_logo.png';
import EnergyStarLogo from '@/assets/LOGO-Energy-Star-Partner.png';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import FibrexTable from '@/assets/fibrex_chart_full_1100w.png'
import styled from "@mui/material/styles/styled";

const StyledHeader = styled(Typography)(() => ({
    color: '#6CC14C',
    fontSize: "clamp(1.5rem, 2.5vw, 2rem)",
    fontWeight: 500,
    textTransform: 'uppercase',
}));

const FibrexBenefitsSection = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));

    const eventMutation = useCreateLeadTokenEventMutation();

    const fibrexRef = useRef<HTMLElement>(null);
    const isFibrexVisible = useOnScreen(fibrexRef);

    useEffect(() => {
        if (isFibrexVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'fibrex_benefits',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'fibrex_benefits'});
        }
    }, [isFibrexVisible]);

    return (<Grid container size={{xs: 12}} justifyContent={'center'} pt={3}>
        <Grid size={{xs: 12}} textAlign={'center'} justifyContent={'center'}>
            <Stack alignItems={'center'}>
                <Typography variant={'h4'} sx={{
                    fontWeight: 700,
                    fontSize: "clamp(1.5rem, 2.5vw, 2.75rem)",
                }} ref={fibrexRef}>
                    Fibrex<sup>®</sup> outshines industry standards
                </Typography>
                <Box
                    component={'img'}
                    alt="Fibrex Benefits"
                    src={FibrexTable}
                    sx={{width: mobile ? '95%':'70%', mt: 5}}
                />
                <Box sx={{width: mobile ? '95%' : '50%', mt: 3}}>
                    <Typography variant={'body2'} sx={{fontSize: "clamp(1rem, 2.5vw, 1.5rem)"}}>
                        Renewal by Andersen is the <b>only</b> place you can get custom designed windows made with our exclusive
                        Fibrex composite material.
                    </Typography>
                </Box>
                <Box sx={{width: mobile ? '90%': '50%', mt: 3, textAlign: 'left'}}>
                    <Typography variant={'body1'} sx={{fontWeight: 500, fontSize: "clamp(1.25rem, 2.5vw, 2.75rem)"}}>
                        Do it right the first time with Fibrex
                    </Typography>
                    <Box sx={{pt: 5}}>
                        <StyledHeader>
                            Let the sunlight in
                        </StyledHeader>
                        <Typography variant={'h5'} sx={{fontSize: "clamp(1rem, 2.5vw, 1.5rem)"}}>
                            Unlike vinyl, Fibrex material remains stable and rigid in all climates. Its strength allows
                            for a larger glass area and more natural light in your home.
                        </Typography>
                    </Box>
                    <Box sx={{pt: 5}}>
                        <StyledHeader>
                            Energy-saving performance
                        </StyledHeader>
                        <Typography variant={'h5'} sx={{fontSize: "clamp(1rem, 2.5vw, 1.5rem)"}}>
                            Fibrex material's superior insulating qualities help block thermal transfer 700x better than
                            aluminum and can dramatically reduce heating and cooling costs.<sup
                            style={{fontSize: 11}}>2</sup>
                        </Typography>
                    </Box>
                    <Box sx={{pt: 5}}>
                        <StyledHeader>
                            Built to stand the test of time
                        </StyledHeader>
                        <Typography variant={'h5'} sx={{fontSize: "clamp(1rem, 2.5vw, 1.5rem)"}}>
                            Wood windows require a lot of upkeep. Fibrex material won't rot, warp, crack, pit or
                            corrode.<sup style={{fontSize: 11}}>1</sup> So, our windows will look and perform like the
                            day they were installed for many years to come.
                        </Typography>
                    </Box>
                    <Box sx={{my: 5}}>
                        <Stack direction={mobile ? 'column':'row'} width={'100%'} spacing={2} alignItems={'center'}>
                            <Box
                                component={'img'}
                                alt="Fibrex Material | vastly superior to vinyl"
                                src={FibrexLogo}
                                maxHeight={150}
                                width={mobile ? 300 : 500}
                            />
                            <Box
                                component={'img'}
                                alt="Energy Star Partner"
                                src={EnergyStarLogo}
                                height={100}
                                width={100}
                            />
                            <Box
                                component={'img'}
                                alt="Custom Built in the USA"
                                src={UsaBuiltLogo}
                                height={100}
                                width={100}
                            />
                        </Stack>
                    </Box>
                </Box>
            </Stack>
        </Grid>
    </Grid>);
};

export default FibrexBenefitsSection;
