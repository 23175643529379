import {Box, Button, Link} from '@mui/material';
import Grid from '@mui/material/Grid2';
import type {Dispatch, SetStateAction} from 'react';

type Props = {
    buttonText : string;
    disabled : boolean;
    stepNumber : number;
    setCurrentStepIndex : Dispatch<SetStateAction<number>>;
    setBackOverride : Dispatch<SetStateAction<boolean>>;
};

const QuizNavigation
    = ({buttonText, disabled, stepNumber, setCurrentStepIndex, setBackOverride} : Props) : React.ReactElement => {
        const handleBackNavigate = () => {
            setBackOverride(true);
            setCurrentStepIndex(stepNumber - 1);
        };

        return <Grid size={{xs: 12}} sx={{textAlign: 'center'}}>
            <Button type={'submit'} variant={'contained'} sx={{
                mr: '10px',
                mt: 5,
                borderRadius: 0,
                fontSize: '14px',
                py: 2,
                px: 10,
            }} disabled={disabled}>
                {buttonText}
            </Button>
            {stepNumber !== 1 && <Box sx={{mt: 2}}>
                <Link sx={{mt: 2, ':hover': {cursor: 'pointer'}}} onClick={handleBackNavigate}>&lt;Back</Link>
            </Box>}
        </Grid>;
    };

export default QuizNavigation;
