import DesignServicesIcon from '@mui/icons-material/DesignServices';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HardwareIcon from '@mui/icons-material/Hardware';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {Box, Divider, Paper, Stack} from '@mui/material';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import {useEffect, useRef} from 'react';
import TagManager from 'react-gtm-module';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import BeautyImage from 'src/assets/our-process-beauty-image.png';

const StyledPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: '16px',
}));

const OurProcess = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'our_process',
                    action: 'scroll',
                },
            });
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'our_process'});
        }
    }, [isSectionVisible]);

    return <Grid container size={{xs: 12}} sx={{justifyContent: 'center', backgroundColor: '#fbfaf7', pb: 5}}>
        <Grid container size={{xs: 12}} sx={{justifyContent: 'center', textAlign: 'center', my: 5}}>
            <Typography variant={'h3'} sx={{
                fontSize: "clamp(1.4rem, 2.5vw, 3rem)",
            }} ref={ref}>
                We make window and door replacement easy
            </Typography>
        </Grid>
        <Grid container sx={{justifyContent: 'center', alignItems: 'center'}}>
            <Grid size={{xs: 12, md: 6}}>
            <Stack>
                <Box
                    component={'img'}
                    alt="Renewal by Anderson"
                    src={BeautyImage}
                />
                <StyledPaper elevation={5}>
                    <Box sx={{display: 'flex', height: '100%'}}>
                        <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <HandshakeIcon sx={{fontSize: '72px', color: '#6cc14c'}}/>
                        </Box>
                        <Box sx={{flex: 4}}>
                            <Stack>
                                <Typography sx={{fontWeight: 500}}>STEP 1</Typography>
                                <Typography variant={'h5'}>CONSULTATION</Typography>
                                <Divider sx={{width: '25%', backgroundColor: '#f9ac4d', height: '2px', my: 1}}/>
                                <Typography fontSize={'1.25em'}>
                                    Our seasoned design consultants provide a free, no obligation in-home window and
                                    door inspection and consultation. They listen to your needs and provide an
                                    affordable solution.
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </StyledPaper>
                <StyledPaper elevation={5}>
                    <Box sx={{display: 'flex', height: '100%'}}>
                        <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <DesignServicesIcon sx={{fontSize: '72px', color: '#6cc14c'}}/>
                        </Box>
                        <Box sx={{flex: 4}}>
                            <Stack>
                                <Typography sx={{fontWeight: 500}}>STEP 2</Typography>
                                <Typography variant={'h5'}>MEASURE AND MANUFACTURE</Typography>
                                <Divider sx={{width: '25%', backgroundColor: '#f9ac4d', height: '2px', my: 1}}/>
                                <Typography fontSize={'1.25em'}>
                                    An experienced measure tech will take precise measurements and answer any
                                    last-minute question you have about your project. From there, an order is placed for
                                    your fully customized windows/doors.
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </StyledPaper>
                <StyledPaper elevation={5}>
                    <Box sx={{display: 'flex', height: '100%'}}>
                        <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <HardwareIcon sx={{fontSize: '72px', color: '#6cc14c'}}/>
                        </Box>
                        <Box sx={{flex: 4}}>
                            <Stack>
                                <Typography sx={{fontWeight: 500}}>STEP 3</Typography>
                                <Typography variant={'h5'}>INSTALLATION</Typography>
                                <Divider sx={{width: '25%', backgroundColor: '#f9ac4d', height: '2px', my: 1}}/>
                                <Typography fontSize={'1.25em'}>
                                    Installations are done year-round by our highly trained Installation Team. They take
                                    exceptional care of you and your home and ensure everything is spotless when the job
                                    is completed.
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </StyledPaper>
                <StyledPaper elevation={5}>
                    <Box sx={{display: 'flex', height: '100%'}}>
                        <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <VerifiedUserIcon sx={{fontSize: '72px', color: '#6cc14c'}}/>
                        </Box>
                        <Box sx={{flex: 4}}>
                            <Stack>
                                <Typography sx={{fontWeight: 500}}>STEP 4</Typography>
                                <Typography variant={'h5'}>WARRANTY</Typography>
                                <Divider sx={{width: '25%', backgroundColor: '#f9ac4d', height: '2px', my: 1}}/>
                                <Typography fontSize={'1.25em'}>
                                    Renewal by Andersen windows are backed by the Nation’s Best warranty<sup style={{fontSize: 11}}>†</sup>,
                                    and, it's fully-transferable!
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </StyledPaper>
            </Stack>
            </Grid>
        </Grid>
    </Grid>;
};

export default OurProcess;
