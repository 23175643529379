import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import TagManager from 'react-gtm-module';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import {useLsrcPhoneNumberQuery} from '@/queries/lsrc';
import type {LeadToken} from '@/types/lead-token';

export const defaultPhoneNumber = '1-800-501-1400';

const PhoneButtonDisplay = ({leadToken, sourceId} : {leadToken : LeadToken; sourceId : string | null}) : JSX.Element => {
    const phoneNumberQuery = useLsrcPhoneNumberQuery(sourceId);
    const eventMutation = useCreateLeadTokenEventMutation();

    const handleClick = async () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'phone_button',
                action: 'click',
            },
        });
        await eventMutation.mutateAsync({leadTokenId: leadToken.id, type: 'click', name: 'phone_button'});
    };

    return <a
                style={{textDecoration: 'none', color: 'inherit'}}
                href={'tel:' + (phoneNumberQuery.data ?? defaultPhoneNumber)}
                onClick={handleClick}
            >
                <Box
                    color="#000"
                    bgcolor={'#f9ac4d'}
                    borderRadius={6}
                    px={8}
                    py={1}
                    display="flex"
                    alignItems="center"
                >
                    <Typography variant={'body1'} display="inline" sx={{fontWeight: '700', fontSize: 20}}>
                        CALL NOW: {phoneNumberQuery.data ?? defaultPhoneNumber}
                    </Typography>
                </Box>
            </a>;
};

const PhoneButton = ({leadToken} : {leadToken : LeadToken}) : JSX.Element => {
    return <PhoneButtonDisplay leadToken={leadToken} sourceId={leadToken.sourceId}/>;
};

export default PhoneButton;
